import React, { useEffect } from "react";
import mainBg from "../../assets/blogImg.jpg";
import blog1Image from "../../assets/aboutus.jpg";
import blog2Image from "../../assets/home_bg.jpg";
import blog3Image from "../../assets/aboutus.jpg";
import blog4Image from "../../assets/home_bg.jpg";
import { GrLinkNext } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { getMyProducts } from "./store/bidsWonSlice";
import { getImageUrl } from "../../helper";
import { useDispatch, useSelector } from 'react-redux';
import im2 from "../../assets/home_bg.jpg";
import MetaTag from "../../components/metaTag";

const blogsData = [
  {
    id: 1,
    title: "Blog 1",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    image: blog1Image,
  },
  {
    id: 2,
    title: "Blog 2",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    image: blog2Image,
  },
  {
    id: 3,
    title: "Blog 3",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    image: blog3Image,
  },
  {
    id: 4,
    title: "Blog 4",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    image: blog4Image,
  },
  {
    id: 5,
    title: "Blog 5",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    image: blog1Image,
  },
  {
    id: 6,
    title: "Blog 6",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    image: blog2Image,
  },
  {
    id: 7,
    title: "Blog 7",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    image: blog3Image,
  },
  {
    id: 8,
    title: "Blog 8",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    image: blog4Image,
  },
  {
    id: 9,
    title: "Blog 9",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    image: blog1Image,
  },
  {
    id: 10,
    title: "Blog 10",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    image: blog2Image,
  },
];

function Blogs() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const myProducts = useSelector(({ bidsWon }) => bidsWon.data);
  console.log({ myProducts })
  const getProductsData = () => {
    dispatch(getMyProducts({ page: 0, limit: 1000 }))
  }

  useEffect(() => {
    getProductsData()
  }, []);

  return (
    <div className="min-h-screen bg-white pt-6 px-4 md:px-8 pb-14">
      <MetaTag />
      <div
        style={{ backgroundImage: `url(${mainBg})` }}
        className="object-fit py-14 w-full h-96 bg-center bg-cover flex flex-col items-center justify-center rounded-2xl"
      >
        <h1 className="w-full text-3xl md:text-5xl font-bold text-neutral-50 text-center mb-4 ">
          Moving Treasure
        </h1>
        <p className="text-base md:text-2xl w-9/12 md:w-1/2 font-light text-neutral-100 text-center">
          Your Wins
        </p>
      </div>
      <h2 className="text-2xl md:text-3xl font-bold mt-8 text-center">
        Your Wins
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-5 gap-8 mt-8">
        {myProducts.map(item => {
          return <div onClick={() => navigate(`/item/${item.id}`)} className="md:w-64 bg-transparent hover:shadow-md rounded-2xl overflow-hidden hover:cursor-pointer transform hover:scale-105 transition-transform ">
            <img
              src={item?.images.length > 0 ? getImageUrl(item?.images[0]) : im2}
              alt="Dummy Shipments"
              className="w-full h-40 object-cover rounded-2xl"
            />
            <div className="p-4 pb-6">
              <div className="w-full mb-4">
                <h3 className="text-base font-bold text-zinc-900">
                  {item.name}
                </h3>
                <p className="text-zinc-700 text-sm font-bold">
                  Total Pieces: {item.numberOfPieces}
                </p>
              </div>
              <button className="bg-amber-500 text-white px-4 py-2 rounded-2xl hover:bg-amber-700 w-full font-bold">
                View
              </button>
            </div>
          </div>
        })}
        {myProducts?.length === 0 && <div className="col-span-5 text-center text-lg text-zinc-600 font-semibold">
          No Data for You </div>}
      </div>
    </div>
  );
}

export default Blogs;
