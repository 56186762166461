import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';

const Navbar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const user = useSelector(({ auth }) => auth.user.data);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleMenuOpen = (path) =>{
    console.log(path);
    setMenuOpen(false)
    navigate(path)
  }
  
  const isLogedIn = user?.id;
  return (
    <nav className="flex items-center justify-between w-full">
      <div className="bg-[#e0edf4] w-full flex items-center justify-between md:justify-center p-3 px-6 rounded-full mt-4 mx-4 md:mx-8 opacity-95 ">
        <a
          onClick={() => handleMenuOpen("/")}
          className="text-zinc-900 w-1/2 md:w-1/4 font-bold text-lg hover:cursor-pointer ml-4"
        >
          Moving Treasure
        </a>

        <div className="hidden md:flex flex-1 items-center justify-center space-x-6 mr-5 content-center">
          <a
            onClick={() => handleMenuOpen("/")}
            className="text-zinc-900 font-bold hover:text-amber-400 hover:cursor-pointer"
          >
            Home
          </a>
          {isLogedIn && <a
            onClick={() => handleMenuOpen("/BidsWon")}
            className="text-zinc-900 font-bold hover:text-amber-400 hover:cursor-pointer"
          >
            Bids Won
          </a>}
          <a
            onClick={() => handleMenuOpen("/Blogs")}
            className="text-zinc-900 font-bold hover:text-amber-400 hover:cursor-pointer"
          >
            Blog
          </a>
          <a
            onClick={() => handleMenuOpen("/AboutUs")}
            className="text-zinc-900 font-bold hover:text-amber-400 hover:cursor-pointer"
          >
            About Us
          </a>
          <a
            onClick={() => handleMenuOpen("/ContactUs")}
            className="text-zinc-900 font-bold hover:text-amber-400 hover:cursor-pointer"
          >
            Contact Us
          </a>
        </div>
        {

          <div className="hidden md:flex items-center space-x-4 justify-end w-1/4">
            {
              user?.id
                ? <button
                  className="bg-amber-500 px-5 py-1 rounded-lg font-bold text-neutral-100 flex items-center justify-center hover:shadow-sm hover:shadow-amber-700"
                  onClick={() => {
                    handleMenuOpen("/Profile");
                  }}
                >
                  Profile
                </button>
                : <button
                  className="bg-amber-500 px-5 py-1 rounded-lg font-bold text-neutral-100 flex items-center justify-center hover:shadow-sm hover:shadow-amber-700"
                  onClick={() => {
                    handleMenuOpen("/SignIn");
                  }}
                >
                  Login
                </button>
            }
          </div>
        }
        <div className="lg:hidden">
          <button
            onClick={toggleMenu}
            className="text-zince-900 focus:outline-none transition-transform duration-300 transform"
          >
            {isMenuOpen ? (
              // Cross icon for when the menu is open
              <svg
                className="w-6 h-6 transition-transform transform rotate-90"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            ) : (
              <svg
                className="w-6 h-6 transition-transform transform -rotate-0"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              </svg>
            )}
          </button>
        </div>
      </div>

      {isMenuOpen && (
        <div className="absolute rounded-xl shadow-md w-[40vw] md:w-3/4 bg-white overflow-hidden right-4 top-16 text-sm z-10 flex flex-col items-center justify-center">
          <a
            onClick={() => handleMenuOpen("/")}
            className="block font-bold text-zinc-600 py-2 hover:text-amber-500 border-b border-gray-200 w-9/12 text-center hover:cursor-pointer"
          >
            Home
          </a>
          <a
            onClick={() => handleMenuOpen("/Blogs")}
            className="block font-bold text-zinc-600 py-2 hover:text-amber-500 border-b border-gray-200 w-9/12 text-center hover:cursor-pointer"
          >
            Blog
          </a>
          <a
            onClick={() => handleMenuOpen("/AboutUs")}
            className="block font-bold text-zinc-600 py-2 hover:text-amber-500 border-b border-gray-200 w-9/12 text-center hover:cursor-pointer"
          >
            About Us
          </a>
          <a
            onClick={() => {
              handleMenuOpen("/ContactUs");
            }}
            className="block font-bold text-zinc-600 py-2 hover:text-amber-500 border-b border-gray-200 w-9/12 text-center hover:cursor-pointer"
          >
            Contact Us
          </a>
          <button
            className="font-bold text-zinc-600 py-2 hover:text-amber-500 hover:cursor-pointer"
            onClick={() => {
              handleMenuOpen("/SignIn");
            }}
          >
            Login
          </button>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
