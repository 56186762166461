import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import axios from 'axios';

export const getBlogs = createAsyncThunk(
  'blogsApp/blogs/getBlogs',
  async ({ page, limit, userId }, { dispatch }) => {
    const response = await axios.get('blogs', {
      params: {
        page: page,
        limit,
        userId,
      },
    });

    dispatch(setTotalResults(response.data.total));
    return response.data.results;
  }
);

export const getBlog = createAsyncThunk(
  'blogsApp/blogs/getBlog',
  async ({ blogId }, { dispatch }) => {
    const response = await axios.get(`blogs/${blogId}`);
    return response.data;
  }
);

const blogsSlice = createSlice({
  name: 'blogsApp/blogs',
  initialState: {
    totalResults: 0,
    data: [],
  },
  reducers: {
    setTotalResults: {
      reducer: (state, action) => {
        state.totalResults = action.payload ? action.payload : 0;
      },
      prepare: totalResults => ({ payload: totalResults || '' }),
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getBlogs.fulfilled, (state, action) => {
        state.data = action.payload
      })
  },
});

export const {
  setTotalResults,
} = blogsSlice.actions;

export default blogsSlice.reducer;

