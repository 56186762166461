import React, { useEffect, useState } from "react";
import blogImg from "../../assets/blogBg.jpg";
import { useParams } from "react-router-dom";
import { getBlog } from "./store/blogsSlice";
import { useDispatch } from 'react-redux';
import { getImageUrl } from "../../helper";
import Loader from '../../components/loader'
import { Helmet } from "react-helmet";
import {
  FacebookShareButton,
  FacebookIcon,
} from "react-share";
import MetaTag from "../../components/metaTag";

const BlogItem = () => {
  const [loading, setLoading] = useState(true);
  const [blog, setBlog] = useState(null);
  const { blogId } = useParams();
  const dispatch = useDispatch();

  const getBlogData = () => {
    dispatch(getBlog({ blogId })).then(({ payload }) => {
      if (payload) {
        setBlog(payload)
      } else {

      }
      setLoading(false)
    })
  }
  useEffect(() => {
    getBlogData()
  }, []);

  if (loading) return <Loader />
  // const renderHedar = () => {
  //   return (
  //     <div className="application">
  //       <Helmet>
  //         <meta charSet="utf-8" />
  //         <title>{blog.title}</title>
  //         <meta property="og:type" content="website" />
  //         <meta property="og:title" content="Moving Treasure - Find It. Bid It. Win It" />
  //         <meta property="og:description" content="Abandoned or unpaid shipments with mysterious valuables, collectibles, and interesting items waiting for you." />
  //         <meta property="og:image" content={getImageUrl(blog.images[0])} />
  //       </Helmet>
  //     </div>
  //   );
  // }
  return (
    <div className="flex flex-col items-center justify-center mx-2 my-4 md:mx-8">
      <MetaTag url={`https://movingtreasure.com/Blogs/${blog.id}`} type="article" title={blog.title} description={blog.description} image={getImageUrl(blog.images[0])} />
      {/* {renderHedar()} */}
      <div className="h-full md:h-100 w-1/2 mx-auto bg-cover bg-center relative">
        <img
          src={getImageUrl(blog.images[0])}
          alt="Blog Cover"
          className="w-full h-full object-cover rounded-3xl"
        />
      </div>
      <p className="text-base md:text-xl font-bold text-dark-500 italic mt-4">{blog.title}</p >
      <div className="flex items-center justify-center mt-4">
        <FacebookShareButton
          url={`https://movingtreasure.com/Blogs/${blog.id}`}
          quote={blog.dispatch}
          hashtag='#moving_treasure'
          className="Demo__some-network__share-button"
        >
          <FacebookIcon size={40} round />
        </FacebookShareButton>
      </div>
      <div style={{ whiteSpace: 'pre-line' }} className="w-full mt-8 px-4 mb-10">
        <p className="text-base md:text-xl text-dark-500 mt-4">{blog.description}</p >
      </div>
    </div>
  );
}


export default BlogItem;
