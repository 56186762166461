import React, { useState, useEffect } from "react";
import "animate.css";
import homeBg from "../../assets/home_bg.jpg";
import { useNavigate } from "react-router-dom";
import { getProducts } from "./store/productsSlice";
import { useDispatch, useSelector } from 'react-redux';
import { getImageUrl } from "../../helper";
import MetaTag from "../../components/metaTag";

const Home = () => {
  const navigate = useNavigate();
  const [shipments, setShipments] = useState(0);
  const [sold, setSold] = useState(0);
  const [shipping, setShipping] = useState(0);
  const dispatch = useDispatch()
  const totalResults = useSelector(({ products }) => products.totalResults);
  const products = useSelector(({ products }) => products.data);

  const getProductsData = () => {
    dispatch(getProducts({ page: 0, limit: 1000 }))
  }

  useEffect(() => {
    getProductsData()
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setShipments((prevValue) => {
        const increment = Math.ceil(totalResults / 150);
        return prevValue + increment <= totalResults ? prevValue + increment : totalResults;
      });
    }, 10);

    return () => clearInterval(interval);
  }, [shipments, totalResults]);

  useEffect(() => {
    const interval = setInterval(() => {
      setSold((prevValue) => {
        const increment = Math.ceil(1000 / 50);
        return prevValue + increment <= 1000 ? prevValue + increment : 1000;
      });
    }, 10);

    return () => clearInterval(interval);
  }, [sold]);

  useEffect(() => {
    const interval = setInterval(() => {
      setShipping((prevValue) => {
        const increment = Math.ceil(48 / 150);
        return prevValue + increment <= 48 ? prevValue + increment : 48;
      });
    }, 10);

    return () => clearInterval(interval);
  }, [shipping]);

  return (
    <div className="min-h-screen bg-white pt-6 px-4 md:px-8 pb-14">
      <MetaTag />
      <div className="h-full mb-10 flex flex-col md:flex-row mt-2">
        <div
          style={{ backgroundImage: `url(${homeBg})` }}
          className="object-fit w-full py-14 md:w-3/4 bg-center bg-cover flex flex-col items-center justify-center rounded-2xl"
        >
          <h1 className="w-full text-3xl md:text-5xl font-bold text-neutral-50 text-center mb-4 animate__animated animate__zoomIn animate__slower">
            Moving Treasure
          </h1>
          <p className="text-base md:text-2xl w-9/12 md:w-1/2 font-light text-neutral-100 text-center animate__animated animate__zoomIn animate__slower">
            Abandoned or unpaid shipments with mysterious valuables,
            collectibles, and interesting items waiting for you.
          </p>
        </div>
        <div className="bg-[#e0edf4] p-4 py-10 mt-4 md:mt-0 md:w-1/4 flex items-center rounded-2xl md:ml-3">
          <div className="max-w-3xl mx-auto flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-4">
            <div className="bg-transparent rounded-md flex-row items-center justify-center text-center">
              <span className="text-5xl text-amber-500 font-bold text-center">
                {shipments}+
              </span>
              <h2 className="text-xl font-bold mt-2 text-zinc-900">
                Abandoned Shipments
              </h2>
              <p className="text-base text-zinc-600">
                More than 100 abandoned shipments waiting for you
              </p>
            </div>
            <div className="bg-transparent rounded-md flex-row items-center justify-center text-center">
              <span className="text-5xl text-amber-500 font-bold text-center">
                {sold}+
              </span>
              <h2 className="text-xl font-bold mt-2 text-zinc-900">
                Pieces Sold
              </h2>
              <p className="text-base text-zinc-600">
                More than 1,000 pieces sold through our service
              </p>
            </div>
            <div className="bg-transparent rounded-md flex-row items-center justify-center text-center">
              <span className="text-5xl text-amber-500 font-bold text-center">
                {shipping}+
              </span>
              <h2 className="text-xl font-bold mt-2 text-zinc-900">Shipping</h2>
              <p className="text-base text-zinc-600">
                Our team can ship to all states
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="h-full w-full mt-8 flex flex-col items-center justify-center text-center">
        <h2 className="text-3xl text-zinc-700 md:text-5xl font-bold mb-4">
          Abandoned Shipments
        </h2>
        <p className="text-lg text-zinc-700 font-semibold">
          Abandoned or unpaid shipments with mysterious valuables, collectibles,
          and interesting items waiting for you.
        </p>
        <div className="mt-4 grid grid-cols-2 md:grid-cols-3 gap-4">
          {products.map(item => {
            return <div onClick={() => navigate(`/item/${item.id}`)} className="md:w-64 bg-transparent hover:shadow-md rounded-2xl overflow-hidden hover:cursor-pointer transform hover:scale-105 transition-transform ">
              <img
                src={item?.images.length > 0 ? getImageUrl(item?.images[0]) : homeBg}
                alt="Dummy Shipments"
                className="w-full h-40 object-cover rounded-2xl"
              />
              <div className="p-4 pb-6">
                <div className="w-full mb-4">
                  <h3 className="text-base font-bold text-zinc-900">
                    {item.name}
                  </h3>
                  <p className="text-zinc-700 text-sm font-bold">
                    Total Pieces: {item.numberOfPieces}
                  </p>
                </div>
                <button className="bg-amber-500 text-white px-4 py-2 rounded-2xl hover:bg-amber-700 w-full font-bold">
                  View
                </button>
              </div>
            </div>
          })}

        </div>
      </div>
    </div>
  );
};

export default Home;
