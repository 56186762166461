import React from "react";
import MetaTag from "../../components/metaTag";

function PrivacyPolicy() {
  return (<>
    <div className="w-full mt-8 px-4 mb-10 pl-20 pr-20">
      <MetaTag />
      <p className="text-base md:text-xl text-dark-500 mt-4" >MovingTreasure.com Privacy Policy</p>
      <p className="text-base  text-dark-500 mt-4" >Scope of this Policy</p>
      <p className="text-base  text-dark-500 mt-4"  >
        This Privacy Policy (“Policy”) describes how MovingTreasure.com, collects, uses, and otherwise processes personal information relating to users and visitors of www.MovingTreasure.com. and all other websites owned and maintained by MovingTreasure.com, (collectively, the “Websites”), and the MovingTreasure.com Mobile Application (the “App”).
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        This Policy does not apply to “offline” activities or activities unrelated to the Websites and App unless otherwise expressly stated herein.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        In this Policy, “personal information” means information relating to an identified or identifiable natural person. An identifiable person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, and/or online identifier or to one or more factors specific to his/her physical, physiological, genetic, mental, economic, cultural, or social identity. Personal information does not include aggregate information or de-identified information, meaning that the information can no longer be attributed to an identifiable natural person without the use of additional information.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Personal information is required for us to be able to provide the services to you. In addition, some of the services we provide require that we disclose your personal information to third parties, such as to companies that help us to provide our services or process data on our behalf. If you are not willing to provide your personal information and have it disclosed to third parties in accordance with this Policy, you will not be able to use our services.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        By accessing and using the Websites or App, User acknowledges that they have read, understand, and agree to be legally bound by this Policy. User must provide explicit consent to the processing of their personal data by clicking the “I Agree” button below or any other appropriate opt-in mechanism provided. If User does not agree with all of the terms of this Policy or does not wish to provide consent, they must discontinue access to and use of the Websites. The terms and conditions of this Policy are incorporated by reference into the Terms of Use Agreement (https://www.MovingTreasure.com.com/terms) for users of MovingTreasure.com’ products and services. User has the right to withdraw their consent at any time, which can be done by contacting MovingTreasure.com as described below or through the User’s account settings. In the event that a User’s consent is withdrawn, barring any legal obligations, we will stop the disclosure of the User’s applicable personal information.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        When MovingTreasure.com is contracted to submit a website to search engines, some personal information may be required by such services, for example: a contact’s name, company’s name, email, website address, etc.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Please note that this Policy applies only to sites maintained by or on behalf of MovingTreasure.com, and not to websites maintained by other companies or organizations to which we link. Such third-party websites may independently solicit and collect personal information from you and, in some instances, provide us with information about your activities on those websites. Third party advertisers may also utilize cookies, web beacons, and similar technologies to collect information regarding your website interactions and activities. These third-party technologies may be utilized by those entities to provide targeted advertising and web links based upon your activities and preferences. If you choose to interact with specific advertisers who advertise on our sites, the information you provide to them is subject to the conditions of their specific privacy policies and in no way covered by the practices described in this policy.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        While we strongly support the protection of privacy on the Internet, we do not have control over, and cannot be responsible for, the actions of other third parties. We encourage you to review each third party’s privacy statement and other otherwise understand its privacy practices, before providing personal information directly to it.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        This Policy does not create rights enforceable by third parties.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Information Collection
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        We may collect various types of information, both personal and anonymous, through our products, services, websites, and apps, to operate effectively and provide you with the best experiences with our services. This information is gathered from your usage of our products and services, as well as from your entry of information on our websites and apps. We obtain some of your information, such as your IP address, by recording how you interact with our services. We may also obtain information about you from our business partners or other third parties, subject to applicable data privacy laws and regulations.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        We may automatically receive and collect certain personal information, as outlined elsewhere in this Policy, and including analytics regarding our websites and apps, information your internet browser automatically sends when you visit our websites and apps, and information collected by cookies. We will collect personal information that can identify you, such as your name and email address, only after obtaining your consent or when it is necessary for a legitimate purpose. We will also collect other information that does not identify you, ensuring that such information is processed in accordance with applicable data privacy laws in the region where you as a user resides.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        MovingTreasure.com may also collect information from users of its mobile applications. The information collected is the same as that collected from users of the Websites, including but not limited to: name, email address, passwords, answers to security questions, and age. MovingTreasure.com may also collect device information such as operating system, version, device type, and IP address. This information will be used to improve the user experience of the mobile application and to provide personalized content.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        MovingTreasure.com may also collect location data from users of its mobile applications in order to provide location-based services. MovingTreasure.com will not collect location data without a user’s explicit consent.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        MovingTreasure.com may also collect push token data from users of its mobile applications in order to provide push notifications. MovingTreasure.com will not collect push token data without a user’s explicit consent.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        MovingTreasure.com may also collect usage data from users of its mobile applications in order to analyze usage patterns and improve the user experience. MovingTreasure.com will not collect usage data without a user’s explicit consent.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        MovingTreasure.com may also collect payment information from users of its mobile applications in order to process payments. MovingTreasure.com will not collect payment information without a user’s explicit consent.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        We may collect demographic and preferences information, survey responses, and other Personally Identifiable Information from you. This information will be anonymized and aggregated in compliance with applicable data privacy laws, ensuring that it becomes non-personally identifiable before being disclosed to our existing or potential business partners, affiliates, sponsors, or other third parties. This process ensures that the aggregate data does not personally identify you or any other visitors to our Websites and Mobile App.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Additionally, we may also collect general, non-personal, statistical information about the use of the Websites and Mobile App, such as how many visitors visit a specific page, and which hyperlinks, if any, they “click” on. In cases where IP addresses or information gathered by cookies are used, such data will be treated as personal information and will be handled according to our Privacy Policy and applicable data privacy laws. We gather this information to identify the most popular areas of our Websites and Mobile App, and to enhance the user experience. Similar to other aggregated data, we may disclose this information to our existing or potential business partners, affiliates, sponsors or other third parties. However, this aggregate data will not personally identify you or any other visitors to our Websites and Mobile App, as proper anonymization techniques are employed.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        We ask for and may collect personal information about you such as your name, address, telephone number, and email address. For certain financial services, personal information may also include data such as payment data and account numbers. You have the option to choose not to provide personal information to us; however, the lack of such personal information will typically prevent us from providing the requested products or services to you.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        User’s non-personal communications, including feedback, questions, comments, and suggestions, will not be considered confidential, and MovingTreasure.com shall have no obligation to protect them from disclosure. MovingTreasure.com shall be free to reproduce, use, disclose and distribute User’s non-personal communications without limitation. MovingTreasure.com shall be free to use any ideas, concepts, know-how, content, or techniques contained in User’s communications for any purpose whatsoever, including but not limited to the development, production and marketing of products and services that incorporate such information.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        From time to time, MovingTreasure.com or a partner, may sponsor a societal cause or contest on one or more of the Websites. In such circumstances, MovingTreasure.com will obtain your consent before sharing any User information, such as email addresses, with these partners for the purpose of the sponsored societal cause or contest.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Use of Your Information
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        This information will only be used in direct conjunction with your account and Websites and App needs. We will not sell Personally Identifiable Information to any sources outside MovingTreasure.com.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        We use information about you for the following general purposes:
      </p>
      <p className="text-base  text-dark-500 mt-4" >
        to provide you with the products and services you request;
      </p>
      <p className="text-base  text-dark-500 mt-4" >
        to communicate with you in general;
      </p>
      <p className="text-base  text-dark-500 mt-4" >
        to respond to your questions and comments;
      </p>
      <p className="text-base  text-dark-500 mt-4" >
        to measure interest in and improve our products, services, and website;
      </p>
      <p className="text-base  text-dark-500 mt-4" >
        to notify you about special offers and products or services that may be of interest to you;
      </p>
      <p className="text-base  text-dark-500 mt-4" >
        to otherwise customize your experience with the Websites and App;
      </p>
      <p className="text-base  text-dark-500 mt-4" >
        to solicit information from you, including surveys; to resolve disputes or troubleshoot problems;
      </p>
      <p className="text-base  text-dark-500 mt-4" >
        to prevent potentially prohibited or illegal activities;
      </p>
      <p className="text-base  text-dark-500 mt-4" >
        to enforce our Terms of Use;
      </p>
      <p className="text-base  text-dark-500 mt-4" >
        to create and manage your account; respond to individual inquiries
      </p>
      <p className="text-base  text-dark-500 mt-4" >
        for legal and compliance purposes; and
      </p>
      <p className="text-base  text-dark-500 mt-4" >
        as otherwise described to you at the point of collection.
      </p>
      <p className="text-base  text-dark-500 mt-4" >
        Sharing Your Information
      </p>
      <p className="text-base  text-dark-500 mt-4" >
        MovingTreasure.com may contract with third-party vendors to provide services for MovingTreasure.com, including, but not limited to maintaining this website, mobile application, mailing lists, collecting payments, and deliver information and services to customers. These third-party vendors have access to and may collect Personally Identifiable Information you provided to MovingTreasure.com only as needed to perform their functions and are not permitted to share or use the information for any other purpose.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        We also may share your information:
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        In response to subpoenas, court orders, or other legal process; to establish or exercise our legal rights; to defend against legal claims; or as otherwise required by law. In such cases we reserve the right to raise or waive any legal objection or right available to us.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        When we believe it is appropriate to investigate, prevent, or take action regarding illegal or suspected illegal activities; to protect and defend the rights, property, or safety of our company or this website, our customers, or others; and in connection with our Terms of Use and other agreements.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        In connection with a corporate transaction, such as a divestiture, merger, consolidation, or asset sale, or in the unlikely event of bankruptcy.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Cookies, Pixel Tags and Web Beacons
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        We and our third-party service providers may use a standard technology called a “cookie” to collect information about how you use the Website. Cookies reside on your computer and help the Websites recognize your computer’s browser as a previous visitor. On occasion our Websites, and/or App may also set a “session cookie” which helps us administer the Websites. The session cookie expires when you close your browser and does not retain any information about you after it expires. If you do not want information collected through the use of cookies, you should be able to modify your browser preference to provide you with choices relating to cookies. If you choose to reject cookies, you may be unable to use certain services on the Websites (e.g., those services that require you to log on to the Websites in order to participate).
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        We and our service providers may also use so-called “pixel tags,” “web beacons,” “clear GIFs” or similar means (collectively, “Pixel Tags”) in connection with some Websites’ pages and HTML-formatted email messages to track your use of our Websites, and/or App in order to provide you with more useful information and a more personalized experience the next time you visit. Pixel Tags do not contain any Personally Identifiable Information and allow us to count users who have visited certain pages of the Websites, to deliver branded services, and to help determine the effectiveness of promotional or advertising campaigns. When used in HTML-formatted email messages, Pixel Tags can tell the sender whether and when an email has been opened.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Ads that may appear on MovingTreasure.com Websites and/or App may be delivered to Users by MovingTreasure.com or one of our advertising partners. Our advertising partners may set cookies. These cookies allow the ad server to recognize Users’ computer or device each time they send User an online advertisement. In this way, ad servers may compile information about where Users, or others who are using User’s computer, saw their advertisements and determine which ads are clicked on. This information allows an ad network to deliver targeted advertisements that they believe will be of most interest to Users.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Retention of Information
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        We store personal information as needed to accomplish the purposes identified in this Policy and to meet legal requirements, including legal and compliance requirements regarding records retention, resolving disputes, and enforcing our agreements. This means that we may be required to maintain your information, for example, to: (1) comply with our legal or regulatory compliance needs (e.g., maintaining records of transactions you have made with us); (2) to exercise, establish or defend legal claims; and/or (3) to protect against fraudulent or abusive activity on our services and systems. For these and possibly other reasons, we may be unable to delete personal information upon request of an individual in certain cases. We may retain different categories of information for different periods of time for the instances stated above. Retention periods for records maintained by us, including those containing personal data, are established based upon business need, statutory and regulatory record keeping requirements in the geographies where we do business, and legal obligations. If you have any further questions about our retention of personal information, please contact us at info@MovingTreasure.com.com.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Access and Interference
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Much of the information on MovingTreasure.com is updated on a real-time basis and is proprietary or is licensed to MovingTreasure.com by our Users or third parties. Users agree that you will not use any robot, spider, scraper or other automated means to access the sites for any purpose without our express handwritten permission. Additionally, Users agree that User will not:
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        take any action that imposes or may impose (in our sole discretion) an unreasonable or disproportionately large load on our infrastructure.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        copy, reproduce, modify, create derivative works from, distribute, or publicly display any content (except for User information) from the site without the prior express written permission of MovingTreasure.com and the appropriate third party, as applicable.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        interfere or attempt to interfere with the proper working of the site, services or tools, or any activities conducted on or with the site, services or tools.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        bypass our robot exclusion headers or other measures MovingTreasure.com may use to prevent or restrict access to the sites.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Correcting/Updating or Removing Information
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        MovingTreasure.com Users may modify or remove any of their personal information at any time by logging into their account. If User’s account has been suspended or deactivated for any reason the User must contact MovingTreasure.com to request assistance with their account. To initiate the contact process, please refer to the contact information provided in the “Contact Us” section of this policy.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Communications Opt-Out
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        MovingTreasure.com may communicate with consent from the User, through emails and/or text messages regarding MFA (Multi-Factor Authentication), marketing purposes, and case management offers.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Regarding text messages, auction buyers may:
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        opt-out from receiving text messages related to:
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        MFA, replying STOP to MFA text message. In doing so, auction buyers must rely on "Call" button to get verification codes.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Case management offers, replying STOP to an offer text message, or updating delivery preferences on the website.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        request additional information replying HELP.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Text message delivery frequency depends on specific scenarios.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Message and data rates may apply.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Regarding emails, Users who do not wish to receive MovingTreasure.com.com:
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        electronic newsletters may unsubscribe by clicking the unsubscribe link in any email or by contacting MovingTreasure.com at info@MovingTreasure.com.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        transactional emails must delete their account by contacting MovingTreasure.com at info@MovingTreasure.com.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Security of Information
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        We implement and maintain physical, administrative, and technical safeguards designed to secure user information and prevent unauthorized access to or disclosure of that information. We strive to protect the user information that we collect and store, however, no security program is 100% secure and we cannot guarantee that our safeguards will prevent every unauthorized attempt to access, use, or disclose personal information.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Responding to Do Not Track Signals
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        MovingTreasure.com does not currently respond or take any action regarding "Do Not Track" requests. Except as provided in this Policy, our Websites do not allow any other parties to collect Personally Identifiable Information about your online activities over time or across different websites when using the Websites.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Transactions with E-Commerce Partners, Vendors and Advertisers
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Please be aware that at times, at the request of or on behalf of our e-commerce partners, vendors or advertisers, MovingTreasure.com may “pre-populate” forms with your Personally Identifiable Information for your review, and, if you so choose, for submission to such e-commerce partners, vendors or advertisers. Accordingly, we encourage you to review each pre-populated form carefully before agreeing to submit your Personally Identifiable Information to determine who is seeking to collect your information, and to familiarize yourself with the applicable privacy policy governing use of any Personally Identifiable Information collected about you.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Children's Privacy
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        MovingTreasure.com does not knowingly collect, use, maintain, process or disclose Personally Identifiable Information from anyone under the age of 16. If MovingTreasure.com becomes aware that any child under the age of 16 has provided Personally Identifiable Information through the use of this website, MovingTreasure.com will delete such information from its files.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Your Privacy Rights
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        In the event that your personal information is found to be outdated, incomplete, or inaccurate, you may access and update your information by contacting us at info@movingTreasure.com
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        If you are a resident of California
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        The California Consumer Privacy Act (CCPA) grants you the following rights concerning your personal information:
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Disclosure of information about our collection of your personal information;
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Disclosure of information regarding the sale or sharing of your personal information;
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        A list of categories of personal information collected about you in the preceding 12 months, including: a. categories of personal information collected about you; b. categories of sources from which the personal information is collected; c. the business or commercial purpose for collecting or selling personal information; d. categories of third parties with whom we share personal information; and e. specific pieces of information that we have collected about you;
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Deletion of any personal information about you that we have collected from you;
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Right to correct inaccurate personal information that we have collected about you;
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Right to opt-out of the sale or sharing of your personal information, if applicable;
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Right to know about any financial incentives offered for the collection, sale, or deletion of your personal information, and to not be discriminated against for exercising your rights.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        These rights are subject to certain limitations. You have the right not to face discrimination for exercising any of your rights under the CCPA. As stated above, we do not sell your personal information.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Please note that we may need to take reasonable steps to verify your identity such as confirming your personal information with the information we have within our records.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Authorized agents can make a request under the CCPA on a consumer’s behalf by contacting us via the methods described below.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        You can also contact us if you have any questions, remarks, or complaints in relation to this Notice.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        If you are a resident of Colorado
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        If you are a resident of Colorado, you have specific privacy rights under the Colorado Privacy Act (CPA). These rights include:
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Right to Access: You have the right to request information about the personal data we have collected about you in the preceding 12 months, including the categories of personal data collected, the sources from which the data is collected, the purposes for collecting or selling the data, and the categories of third parties with whom we share the data.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Right to Data Portability: You have the right to obtain a copy of your personal data that we have collected, in a structured, commonly used, and machine-readable format, and to transmit that data to another controller without hindrance, where technically feasible.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Right to Rectification: You have the right to request that we correct any inaccurate or incomplete personal data we hold about you.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Right to Deletion: You have the right to request that we delete your personal data when it is no longer necessary for the purposes for which it was collected, or if you withdraw your consent (if applicable) and there is no other legal basis for processing the data.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Right to Opt-Out: If we sell your personal data, you have the right to opt-out of the sale of your personal data at any time.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Right to Non-Discrimination: You have the right not to be discriminated against for exercising any of your privacy rights under the CPA.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Right to Appeal: If you are not satisfied with our response to your request or believe our processing of your personal data does not comply with the CPA, you have the right to appeal our response to your request.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        To exercise any of these rights, please submit a request to the contact details provided in our privacy policy. Please note that we may need to take reasonable steps to verify your identity before processing your request.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Additionally, you can contact us if you have any questions, remarks, or complaints in relation to this Notice or our privacy practices.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        If you are a resident of Connecticut
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        If you are a resident of Connecticut, you have specific privacy rights under the Connecticut Data Privacy Act (CTDPA). These rights include:
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Right to Access: You have the right to request information about the personal data we have collected about you in the preceding 12 months, including the categories of personal data collected, the sources from which the data is collected, the purposes for collecting or selling the data, and the categories of third parties with whom we share the data.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Right to Data Portability: You have the right to obtain a copy of your personal data that we have collected, in a structured, commonly used, and machine-readable format, and to transmit that data to another controller without hindrance, where technically feasible.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Right to Rectification: You have the right to request that we correct any inaccurate or incomplete personal data we hold about you.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Right to Deletion: You have the right to request that we delete your personal data when it is no longer necessary for the purposes for which it was collected, or if you withdraw your consent (if applicable) and there is no other legal basis for processing the data.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Right to Opt-Out: If we sell your personal data, you have the right to opt-out of the sale of your personal data at any time.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Right to Non-Discrimination: You have the right not to be discriminated against for exercising any of your privacy rights under the CTDPA.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        To exercise any of these rights, please submit a request to the contact details provided in our privacy policy. Please note that we may need to take reasonable steps to verify your identity before processing your request.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Additionally, you can contact us if you have any questions, remarks, or complaints in relation to this Notice or our privacy practices.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        If you are a resident of Utah
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        If you are a resident of Utah, you have specific privacy rights under the Utah Consumer Privacy Act (UCPA). These rights include:
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Right to Access: You have the right to request information about the personal data we have collected about you in the preceding 12 months, including the categories of personal data collected, the sources from which the data is collected, the purposes for collecting the data, and the categories of third parties with whom we share the data.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Right to Data Portability: You have the right to obtain a copy of your personal data that we have collected, in a structured, commonly used, and machine-readable format, and to transmit that data to another controller without hindrance, where technically feasible.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Right to Rectification: You have the right to request that we correct any inaccurate or incomplete personal data we hold about you.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Right to Deletion: You have the right to request that we delete your personal data when it is no longer necessary for the purposes for which it was collected, or if you withdraw your consent (if applicable) and there is no other legal basis for processing the data.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Right to Opt-Out: If we sell your personal data, you have the right to opt-out of the sale of your personal data at any time.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Right to Non-Discrimination: You have the right not to be discriminated against for exercising any of your privacy rights under the UCPA.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        To exercise any of these rights, please submit a request to the contact details provided in our privacy policy. Please note that we may need to take reasonable steps to verify your identity before processing your request.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Additionally, you can contact us if you have any questions, remarks, or complaints in relation to this Notice or our privacy practices.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        If you are a resident of Virginia
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        If you are a resident of Virginia, you have specific privacy rights under the Virginia Consumer Data Protection Act (VCDPA). These rights include:
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Right to Access: You have the right to request information about the personal data we have collected about you in the preceding 12 months, including the categories of personal data collected, the purposes for which the data is used, and the categories of third parties with whom we share the data.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Right to Data Portability: You have the right to obtain a copy of your personal data that we have collected, in a structured, commonly used, and machine-readable format, and to transmit that data to another controller without hindrance, where technically feasible.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Right to Rectification: You have the right to request that we correct any inaccurate or incomplete personal data we hold about you.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Right to Deletion: You have the right to request that we delete your personal data when it is no longer necessary for the purposes for which it was collected, or if you withdraw your consent (if applicable) and there is no other legal basis for processing the data.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Right to Opt-Out: If we sell your personal data or process it for targeted advertising purposes, you have the right to opt-out of such sale or processing at any time.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Right to Non-Discrimination: You have the right not to be discriminated against for exercising any of your privacy rights under the VCDPA.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Right to Appeal: If you are not satisfied with our response to your request or believe our processing of your personal data does not comply with the VCDPA, you have the right to lodge a complaint with the Virginia Attorney General’s Office.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        To exercise any of these rights, please submit a request to the contact details provided in our privacy policy. Please note that we may need to take reasonable steps to verify your identity before processing your request.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Additionally, you can contact us if you have any questions, remarks, or complaints in relation to this Notice or our privacy practices.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Legal Basis for Processing California Consumer Privacy Act and California Privacy Rights Act (CCPA and CPRA)
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        If you are a resident of California, the California Consumer Privacy Act (CCPA) and the California Privacy Rights Act (CPRA) provide you with specific rights regarding your personal information. Our legal basis for processing your personal information under these acts will depend on the context in which we collect it.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Performance of a Contract: When we collect your personal data to provide you with products and services you have requested, our legal basis for processing that personal data is the necessity to perform a contract to which you are a party or to take steps at your request before entering into a contract. If you refuse to provide us with your personal data, you may be unable to obtain the products and services from us that you requested.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Business Purpose: We may process your personal information for our legitimate business purposes. These specific business purposes may include, but are not limited to, operating our business, providing customer support, improving our products and services, conducting research and analysis, ensuring the security of our systems, and complying with applicable laws and regulations.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Consent: In certain cases, we may ask for your explicit consent to collect and process your personal information for specific purposes, such as sending you marketing communications or processing sensitive data. If you provide your consent, you have the right to withdraw it at any time, and we will cease processing your data for those purposes upon withdrawal of consent.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Legal Obligations: In certain circumstances, we may be required to process your personal information to comply with applicable laws and regulations, such as the CCPA and CPRA. This may include disclosing your information to law enforcement agencies, government authorities, or as part of legal proceedings.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Sale of Personal Information: Under the CCPA and CPRA, “sale” is defined broadly and may include the sharing of personal information with third parties for valuable consideration. We do not sell your personal information in the traditional sense. However, we may share your personal information with third parties for business purposes, such as analytics, targeted advertising, or providing you with customized offers from us or our partners.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        We always process your personal information in compliance with the CCPA, CPRA, and other applicable data protection laws. If you have any questions or concerns about the legal basis for our processing of your personal information under the CCPA and CPRA, please contact us using the contact details provided on the Contact Us section.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        If you are a resident of Colorado, the Colorado Privacy Act (CPA) provides you with specific rights regarding your personal information. Our legal basis for processing your personal information under the CPA will depend on the context in which we collect it.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Legal Basis for Processing Colorado Privacy Act (CPA)
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Performance of a Contract: When we collect your personal data to provide you with products and services you have requested, our legal basis for processing that personal data is the necessity to perform a contract to which you are a party or to take steps at your request before entering into a contract. If you refuse to provide us with your personal data, you may be unable to obtain the products and services from us that you requested.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Legal Basis for Processing Colorado Privacy Act (CPA)
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Legal Basis for Processing Colorado Privacy Act (CPA)
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Business Purpose: We may process your personal information for our legitimate business purposes. These purposes may include, but are not limited to, operating our business, providing customer support, improving our products and services, conducting research and analysis, ensuring the security of our systems, and complying with applicable laws and regulations.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Legal Basis for Processing Colorado Privacy Act (CPA)
        Consent: In certain cases, we may ask for your explicit consent to collect and process your personal information for specific purposes, such as sending you marketing communications or processing sensitive data. If you provide your consent, you have the right to withdraw it at any time, and we will cease processing your data for those purposes upon withdrawal of consent.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Legal Basis for Processing Colorado Privacy Act (CPA)
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Legal Obligations: In certain circumstances, we may be required to process your personal information to comply with applicable laws and regulations, such as the CPA. This may include disclosing your information to law enforcement agencies, government authorities, or as part of legal proceedings.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Sale, Sharing, or Disclosure of Personal Information: Under the CPA, we may share your personal information with third parties for business purposes, such as analytics, targeted advertising, or providing you with customized offers from us or our partners. We will always ensure that such sharing is done in compliance with the CPA and that your personal information is protected.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        We always process your personal information in compliance with the CPA and other applicable data protection laws. If you have any questions or concerns about the legal basis for our processing of your personal information under the CPA, please contact us using the contact details provided on the Contact Us section.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Legal Basis for Processing Connecticut Data Privacy Act (CTDPA)
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        If you are a resident of Connecticut, the Connecticut Data Privacy Act (CTDPA) provides you with specific rights regarding your personal information. Our legal basis for processing your personal information under the CTDPA will depend on the context in which we collect it.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Performance of a Contract: When we collect your personal data to provide you with products and services you have requested, our legal basis for processing that personal data is the necessity to perform a contract to which you are a party or to take steps at your request before entering into a contract. If you refuse to provide us with your personal data, you may be unable to obtain the products and services from us that you requested.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Business Purpose: We may process your personal information for our legitimate business purposes. These purposes may include, but are not limited to, operating our business, providing customer support, improving our products and services, conducting research and analysis, ensuring the security of our systems, and complying with applicable laws and regulations.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Consent: In certain cases, we may ask for your explicit consent to collect and process your personal information for specific purposes, such as sending you marketing communications or processing sensitive data. If you provide your consent, you have the right to withdraw it at any time, and we will cease processing your data for those purposes upon withdrawal of consent.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Legal Obligations: In certain circumstances, we may be required to process your personal information to comply with applicable laws and regulations, such as the CTDPA. This may include disclosing your information to law enforcement agencies, government authorities, or as part of legal proceedings.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Sale, Sharing, or Disclosure of Personal Information: Under the CTDPA, we may share your personal information with third parties for business purposes, such as analytics, targeted advertising, or providing you with customized offers from us or our partners. We will always ensure that such sharing is done in compliance with the CTDPA and that your personal information is protected.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        We always process your personal information in compliance with the CTDPA and other applicable data protection laws. If you have any questions or concerns about the legal basis for our processing of your personal information under the CTDPA, please contact us using the contact details provided on the Contact Us section.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Legal Basis for Processing Utah Consumer Privacy Act (UCPA)
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        When processing personal information in compliance with the Utah Consumer Privacy Act (UCPA), we adhere to the following legal bases:
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Performance of a Contract: We may process your personal information when it is necessary for the performance of a contract to which you are a party or to take steps at your request prior to entering into a contract. This includes providing you with products and services that you have requested.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Legitimate Interests: We may process your personal information when it is necessary for our legitimate interests or those of a third party, provided that these interests are not overridden by your data protection interests or fundamental rights and freedoms. Examples of legitimate interests include improving our products and services, ensuring the security of our systems, and analyzing user behavior to enhance user experience.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Consent: In certain cases, we may ask for your explicit consent to collect and process your personal information for specific purposes, such as sending you marketing communications, conducting surveys, or processing sensitive data. You have the right to withdraw your consent at any time, and we will cease processing your data for those purposes upon withdrawal of consent.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Compliance with Legal Obligations: In certain circumstances, we may be required to process your personal information to comply with applicable laws and regulations. For example, we may need to disclose your information to law enforcement agencies, government authorities, or as part of legal proceedings.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Protection of Vital Interests: We may process your personal data when it is necessary to protect your vital interests or those of another individual. This could occur in situations where it is necessary to safeguard someone’s life, health, or well-being.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        When relying on one of these legal bases for processing, we will always ensure that your personal information is handled in compliance with the UCPA’s principles, and your data protection rights. If you have any questions or concerns regarding the legal basis for our processing of your personal information, please contact us using the contact details provided on the Contact Us section.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Legal Basis for Processing Virginia Consumer Data Protection Act (VCDPA)
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        When processing personal data in compliance with the Virginia Consumer Data Protection Act (VCDPA), we adhere to the following legal bases:
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Performance of a Contract: We may process your personal information when it is necessary for the performance of a contract to which you are a party or in order to take steps at your request prior to entering into a contract. This includes providing you with products and services that you have requested.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Legitimate Interests: We may process your personal information when it is necessary for our legitimate interests or those of a third party, provided that these interests are not overridden by your data protection interests or fundamental rights and freedoms. Examples of legitimate interests include improving our products and services, ensuring the security of our systems, and analyzing user behavior to enhance user experience.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Consent: In certain cases, we may ask for your explicit consent to collect and process your personal information for specific purposes, such as sending you marketing communications, conducting surveys, or processing sensitive data. You have the right to withdraw your consent at any time, and we will cease processing your data for those purposes upon withdrawal of consent.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Compliance with Legal Obligations: In certain circumstances, we may be required to process your personal information to comply with applicable laws and regulations. For example, we may need to disclose your information to law enforcement agencies, government authorities, or as part of legal proceedings.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Protection of Vital Interests: We may process your personal data when it is necessary to protect your vital interests or those of another individual. This could occur in situations where it is necessary to safeguard someone’s life, health, or well-being.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Public Interest: In some instances, we may process your personal information when it is necessary for performing a task carried out in the public interest, such as cooperating with regulatory authorities or contributing to scientific research.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        When relying on one of these legal bases for processing, we will always ensure that your personal information is handled in compliance with the VCDPA’s principles, and your data protection rights. If you have any questions or concerns regarding the legal basis for our processing of your personal information, please contact us using the contact details provided on the Contact Us section.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Privacy Policy Changes
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        As we develop new products and services, update or expand technologies, and develop new information uses, we reserve the right to update this Policy as necessary to reflect such changes. We recommend that you review this policy periodically for any changes that may have been made. Your continued use of our products, services, apps, and websites subsequent to any changes made after your initial consent means that you agree to be bound by those changes. The most current version of this Privacy Policy supersedes all previous versions.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        Contact Us:
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        If you have any questions or concerns about this Privacy Policy or our privacy practices, please do not hesitate to contact us. You can reach us via email or through postal mail with your written inquiry. Our contact details are as follows:
      </p>
      <p className="text-base  text-dark-500 mt-4" >
        Email: imfo@MovingTreasure.com.com
      </p>
      <p className="text-base  text-dark-500 mt-4" >
        Postal Mail:
      </p>
      <p className="text-base  text-dark-500 mt-4" >
        www.MovingTreasure.com
      </p>
      <p className="text-base  text-dark-500 mt-4" >
        1891 Woolner Ave Ste B,
      </p>
      <p className="text-base  text-dark-500 mt-4" >
        Fairfield, CA 94533
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
        We are committed to addressing any questions, concerns, or requests you may have regarding your privacy rights and our handling of your personal information.
      </p>
      <p className="text-base  text-dark-500 mt-4"  >
      </p>
    </div>
  </>)
}

export default PrivacyPolicy;
