import React, { useState } from "react";
import imgBg from "../../assets/abt_bg.jpg";
import { useDispatch, useSelector } from 'react-redux';
import { createInquiry } from "./store/inquirySlice";
import { useToasts } from 'react-toast-notifications'
import { validateEmail } from '../../helper'
import MetaTag from "../../components/metaTag";

function ContactUs() {
  const user = useSelector(({ auth }) => auth.user.data);
  const [desc, setDesc] = useState('');
  const [userId] = useState(user?.id || 'guest');
  const [userEmail, setUserEmail] = useState(user?.email || '');
  const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber || '');
  const [name, setName] = useState(user?.displayName || '');
  const dispatch = useDispatch();
  const { addToast } = useToasts()

  const showAlert = (connect, isError = true) => {
    addToast(connect, {
      appearance: isError ? "error" : "success",
      autoDismiss: true,
    })
  }

  const sendInquiry = () => {
    if (desc.length === 0 && phoneNumber.length === 0 && name.length === 0) {
      showAlert('Please fill all fields');
      return;
    }
    if (!validateEmail(userEmail)) {
      showAlert('Please enter a valid email address');
      return;
    }
    const reqBody = {
      desc,
      userId,
      userEmail,
      phoneNumber,
      name
    }
    dispatch(createInquiry(reqBody)).then(({ payload }) => {
      if (payload) {
        showAlert('Your question has been created successfully', false)
        setDesc('');
        if(!user?.id){
          setUserEmail('');
          setPhoneNumber('');
          setName('');
        }
      } else {
        showAlert('Failed to create your question, please try again later');
      }
    })
  }
  return (
    <div className="flex justify-center items-center h-fit bg-[#e0edf4] mx-4 my-4 md:mx-8 rounded-3xl">
      <MetaTag />
      <div
        style={{ backgroundImage: `url(${imgBg})` }}
        className="object-cover hidden md:w-1/2 h-[85vh] bg-center bg-cover md:flex flex-col items-center justify-center rounded-2xl"
      >
        <h1 className="w-full text-3xl md:text-5xl font-bold text-neutral-50 text-center mb-4">
          Moving Treasure
        </h1>
        <p className="text-xl w-9/12 md:w-3/4 font-medium text-neutral-100 text-center">
          Abandoned or unpaid shipments with mysterious valuables, collectibles,
          and interesting items waiting for you
        </p>
      </div>
      <div className="w-full flex flex-col justify-center md:w-1/2 p-4 md:p-8 md:px-12">
        <h1 className="text-3xl font-bold mb-4 text-center font-serif text-zinc-700">
          Contact Us
        </h1>

        <div className="flex flex-col justify-center w-full mb-4">
          <p className="text-base font-semibold italic text-zinc-600 mb-1 ml-2">
            For further questions, including partnership opportunities, please
            email hello@creative-tim.com or contact using our contact form.
          </p>
          <div className="flex flex-col w-full">
            <p className="text-base font-bold text-zinc-600 mb-1 ml-2">Name</p>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              readOnly={user?.id !== undefined}
              placeholder="Enter your name"
              className="w-full px-4 py-2 mb-2 border rounded-lg focus:outline-none bg-neutral-50 text-zinc-500"
            />
          </div>
          <div className="flex justify-between w-full">
            <div className="flex flex-col w-1/2 mr-2">
              <p className="text-base font-bold text-zinc-600 mb-1 ml-2">
                Email
              </p>
              <input
                type="text"
                onChange={(e) => setUserEmail(e.target.value)}
                value={userEmail}
                readOnly={user?.id !== undefined}
                placeholder="Enter your email"
                className="w-full px-4 py-2 mb-2 border rounded-lg focus:outline-none bg-neutral-50 text-zinc-500"
              />
            </div>
            <div className="flex flex-col w-1/2 ml-2">
              <p className="text-base font-bold text-zinc-600 mb-1 ml-2">
                Phone Number
              </p>
              <input
                type="text"
                onChange={(e) => setPhoneNumber(e.target.value)}
                value={phoneNumber}
                readOnly={user?.id !== undefined}
                placeholder="Enter your phone number"
                className="w-full px-4 py-2 mb-2 border rounded-lg focus:outline-none bg-neutral-50 text-zinc-500"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center w-full mb-4">
          <p className="text-base font-bold text-zinc-600 mb-1 ml-2">
            What can we help you with?
          </p>
          <textarea
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
            placeholder="Enter your question or message"
            className="w-full px-4 py-2 mb-4 border rounded-lg focus:outline-none focus:border-amber-500 bg-neutral-50 resize-none"
          ></textarea>
        </div>

        {/* Send Message Button */}
        <button onClick={sendInquiry} className="bg-amber-500 px-5 py-2 rounded-lg font-bold text-neutral-100 flex items-center justify-center hover:shadow-md hover:shadow-amber-500 w-1/2 mx-auto mt-2 mb-4">
          Send Message
        </button>
      </div>
    </div>
  );
}

export default ContactUs;
