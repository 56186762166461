import React from "react";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();
  return (
    <div>
      <footer className="flex flex-col items-center justify-center w-full h-52 border-t bg-[#e0edf4] p-4 ">
        <h1 className="text-3xl font-bold text-zinc-800 font-serif">
          Moving Treasure
        </h1>
        <div className="flex flex-row items-center mt-2 space-x-3">
          <a
            onClick={() => {
              navigate("/AboutUs");
            }}
            className="text-sm font-bold text-zinc-800 hover:cursor-pointer"
          >
            About Us
          </a>
          <a
            href="/TermsOfUse"
            className="text-sm font-bold text-zinc-800 hover:cursor-pointer"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </a>
          <a
            href="/PrivacyPolicy"
            className="text-sm font-bold text-zinc-800 hover:cursor-pointer"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </div>
        <div className="flex flex-row items-center mt-4 space-x-2">
          <a target="_blank" href="https://www.facebook.com/profile.php?id=61550923056669&mibextid=LQQJ4d">
            <FaFacebookSquare className="text-3xl text-zinc-800" />
          </a>
          <a target="_blank" href="https://instagram.com/moving_treasures?igshid=MzRlODBiNWFlZA==">
            <FaInstagramSquare className="text-3xl text-zinc-800" />
          </a>
        </div>

        <p className="text-sm text-center text-zinc-800 mt-10">
          © 2024 Moving Treasure. All rights reserved.
        </p>
      </footer>
    </div>
  );
}

export default Footer;
