import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { updateUserData } from "../../auth/store/userSlice";
import { useToasts } from 'react-toast-notifications'
import imgBg from "../../assets/aboutus.jpg";
import { logoutUser } from "../../auth/store/userSlice";
import MetaTag from "../../components/metaTag";

const Profile = () => {
  const user = useSelector(({ auth }) => auth.user.data);
  const [firstName, setFirstName] = useState(user?.firstName || '');
  const [lastName, setLastName] = useState(user?.lastName || '');
  const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber || '');
  const [address, setAddress] = useState(user?.address || '');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addToast } = useToasts()

  const showAlert = (connect, isError = true) => {
    addToast(connect, {
      appearance: isError ? "error" : "success",
      autoDismiss: true,
    })
  }

  useEffect(() => {
    if (!user.id) {
      navigate('/');
    }
  }, []);

  const saveUserData = () => {
    const reqParams = {
      firstName,
      lastName,
      phoneNumber,
      address
    }
    if (firstName.length === 0 || lastName.length === 0 || phoneNumber.length === 0 || address.length === 0) {
      showAlert('Please fill all fields');
      return;
    }

    if(firstName === user.firstName && lastName === user.lastName && phoneNumber === user.phoneNumber && address === user.address){
      return
    }
    const userID = user.id
    dispatch(updateUserData({ userID, reqParams })).then(({ payload }) => {
      if (payload.id) {
        showAlert('Profile updated successfully', false);
      } else {
        showAlert('Profile update failed please try again later');
      }
    })
  }

  return (
    <div className="min-h-screen mx-4 my-4 md:mx-8">
      <MetaTag />
      <div className="flex justify-center items-center h-full bg-[#e0edf4] rounded-3xl">
        <img
          src={user.photoURL}
          className="w-[10rem] h-[10rem] md:w-[12rem] md:h-[12rem] object-cover rounded-2xl ml-2"
          alt="item"
        />
        <div className="w-full flex flex-col justify-center md:w-1/2 p-8 ">
          <h1 className="text-3xl font-bold mb-8 text-center font-serif text-zinc-700">
            {user.displayName}
          </h1>

          <div className="flex flex-col  justify-center w-full md:w-3/4 mx-auto text-start">
            <p className="text-base font-bold text-zinc-600 mb-1 ml-2 ">
              First Name
            </p>
            <input
              onChange={(e) => setFirstName(e.target.value)}
              defaultValue={user?.firstName || ''}
              type="text"
              placeholder="First Name"
              className="w-full px-4 py-2 mb-4 border rounded-lg focus:outline-none focus:border-amber-500 bg-neutral-50"
            />
          </div>

          <div className="flex flex-col  justify-center w-full md:w-3/4 mx-auto text-start">
            <p className="text-base font-bold text-zinc-600 mb-1 ml-2">
              last Name
            </p>
            <input
              onChange={(e) => setLastName(e.target.value)}
              defaultValue={user?.lastName || ''}
              placeholder="Last Name"
              className="w-full px-4 py-2 mb-4 border rounded-lg focus:outline-none focus:border-amber-500 bg-neutral-50"
            />
          </div>

          <div className="flex flex-col  justify-center w-full md:w-3/4 mx-auto text-start">
            <p className="text-base font-bold text-zinc-600 mb-1 ml-2">
              Phone Number
            </p>
            <input
              onChange={(e) => setPhoneNumber(e.target.value)}
              defaultValue={user?.phoneNumber || ''}
              placeholder="Phone Number"
              className="w-full px-4 py-2 mb-4 border rounded-lg focus:outline-none focus:border-amber-500 bg-neutral-50"
            />
          </div>
          <div className="flex flex-col  justify-center w-full md:w-3/4 mx-auto text-start">
            <p className="text-base font-bold text-zinc-600 mb-1 ml-2">
              Address
            </p>
            <input
              onChange={(e) => setAddress(e.target.value)}
              defaultValue={user?.address || ''}
              placeholder="Address"
              className="w-full px-4 py-2 mb-4 border rounded-lg focus:outline-none focus:border-amber-500 bg-neutral-50"
            />
          </div>
          <button onClick={saveUserData} className="bg-amber-500 px-5 py-2 rounded-lg font-bold text-neutral-100 flex items-center justify-center hover:shadow-md hover:shadow-amber-500 w-1/2 mx-auto mt-4 mb-4">
            Save
          </button>

          <p className="text-center">

            <a
              onClick={() => {
                dispatch(logoutUser())
                navigate("/");
              }}
            >
              <span className="text-amber-500 font-bold text-base normal-case hover:cursor-pointer">
                Logout
              </span>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Profile;
