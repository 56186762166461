import React, { useState, useEffect, useRef } from "react";
import mainBg from "../../assets/blogImg.jpg";
import { GrLinkNext } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { getBlogs } from "./store/blogsSlice";
import { useDispatch, useSelector } from 'react-redux';
import { getImageUrl } from "../../helper";
import MetaTag from "../../components/metaTag";

import {
  FacebookShareButton,
  FacebookIcon,
  FacebookShareCount
} from "react-share";

async function getUrFromService(bligImgId) {
  // The real implementation would make a network call here.
  await new Promise((resolve) => setTimeout(resolve, 1000));
  const imgUrl = getImageUrl(bligImgId)
  return imgUrl
}

function Blogs() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [url, setUrl] = useState("none");
  const shareButton = useRef(null);

  useEffect(() => {
    if (url !== "none") {
      shareButton.current?.click();
      setUrl("none");
    }
  }, [url, shareButton]);


  const onClick = async (blog) => {
    // Be sure to check for the "none" state, so we don't trigger an infinite loop.
    if (url === "none") {
      const newUrl = await getUrFromService(blog.images[0]);
      setUrl(newUrl);
    }
  };

  const getBlogsData = () => {
    dispatch(getBlogs({ page: 0, limit: 1000 }))
  }
  const blogs = useSelector(({ blogs }) => blogs.data);

  useEffect(() => {
    getBlogsData()
  }, []);
  // const shareUrl = location.protocol + '//' +location.host;

  const renderBlogs = (blog) => {
    const blogImgs = blog?.images || [];
    let blogImg = '';
    if (blogImgs.length !== 0) {
      blogImg = getImageUrl(blog.images[0])
    }
    return <div
      key={blog.id}
      className="bg-white md:block flex items-center rounded-3xl md:rounded-xl shadow-md hover:shadow-lg cursor-pointer transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 overflow-hidden"
    onClick={() => navigate(`/Blogs/${blog.id}`)}
    >
      <img
        src={blogImg}
        alt={blog.title}
        className="w-full h-36 object-cover rounded-3xl md:rounded-xl md:mb-2 md:rounded-bl-none md:rounded-br-none"
      />
      <div className="px-4 my-2">
        <h2 className="text-base md:text-lg font-bold mb-1 line-clamp-1">
          {blog.title}
        </h2>
        <p className="text-gray-600 text-sm line-clamp-2">
          {blog.description}
        </p>
        <FacebookShareButton
          url={`https://movingtreasure.com/Blogs/${blog.id}`}
          quote={blog.dispatch}
          hashtag='#moving_treasure'
          className="Demo__some-network__share-button"
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <a
          onClick={() => navigate("/Blogs/item")}
          className="hidden md:flex items-center justify-center text-amber-500 font-semibold mt-4 rounded-full hover:font-bold hover:cursor-pointer"
        >
          Read More{" "}
          <span className="ml-1 mt-1">
            <GrLinkNext />
          </span>
        </a>
      </div>
    </div>
  }
  return (
    <div className="min-h-screen bg-white pt-6 px-4 md:px-8 pb-14">
      <MetaTag />
      <div
        style={{ backgroundImage: `url(${mainBg})` }}
        className="object-fit py-14 w-full h-96 bg-center bg-cover flex flex-col items-center justify-center rounded-2xl"
      >
        <h1 className="w-full text-3xl md:text-5xl font-bold text-neutral-50 text-center mb-4 ">
          Moving Treasure Blogs
        </h1>
        <p className="text-base md:text-2xl w-9/12 md:w-1/2 font-light text-neutral-100 text-center">
          Abandoned or unpaid shipments with mysterious valuables, collectibles,
          and interesting items waiting for you.
        </p>
      </div>
      <h2 className="text-2xl md:text-3xl font-bold mt-8 text-center">
        Latest Blogs
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-5 gap-8 mt-8">
        {blogs.map((blog) => renderBlogs(blog))}
        {/* {blogs.map((blog) => (
          
        ))} */}
      </div>
    </div>
  );
}

export default Blogs;
