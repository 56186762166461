import { configureStore } from '@reduxjs/toolkit';
import product from '../pages/Item/store/productSlice';
import products from '../pages/Home/store/productsSlice';
import auth from '../auth/store';
import bidsWon from '../pages/BidsWon/store/bidsWonSlice';
import blogs from '../pages/Blogs/store/blogsSlice';

export const store = configureStore({
  reducer: {
    auth,
    products,
    product,
    bidsWon,
    blogs,
  }
});