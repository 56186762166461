import React from "react";
// import { Helmet } from "react-helmet";
import { Helmet } from 'react-helmet-async';

const MetaTag = ({
    type = 'website',
    url = "https://movingtreasure.com",
    title = "Moving Treasure - Find It. Bid It. Win It",
    description = "Abandoned or unpaid shipments with mysterious valuables, collectibles, and interesting items waiting for you.",
    image = "https://movingtreasure.com/favicon.ico" }) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name='description' content={description} />
            <meta property="og:image" content={image} />
            <meta property="og:url" content={url} />
            <meta property="og:type" content={type} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
        </Helmet>
    );
    // return <></>
}

export default MetaTag;
