import Lottie from 'react-lottie';
const ErrorAnimation = () => {
    return (<>
        <Lottie
            options={{
                path: 'https://lottie.host/6a116586-8bca-4bef-8995-1950d1510fa2/TEmjU3e3GO.json',
            }}
            width={300}
            height={300}
        />
        <div className='flex flex-col items-center justify-center w-full h-52 '>
            <p className="text-base md:text-xl font-bold text-red-500 italic">Sorry, we couldn't retrieve the data you requested. Please try again later or contact support for assistance</p>
        </div>
    </>)
}

export default ErrorAnimation
