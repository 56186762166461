import React, { useState, useEffect } from "react";
import item_img from "../../assets/item.jpg";
import im2 from "../../assets/home_bg.jpg";
import { useDispatch, useSelector } from 'react-redux';
import { getProduct, updateValue } from "./store/productSlice";
import { useParams } from "react-router-dom";
import { useToasts } from 'react-toast-notifications'
import Loader from '../../components/loader'
import ErrorAnimation from '../../components/errorAnimation'
import moment from "moment";
import { getImageUrl } from "../../helper";
import { useNavigate } from "react-router-dom";
import MetaTag from "../../components/metaTag";

const Item = () => {
  const [images, setImages] = useState([im2]);
  const [isLogin, setIsLogin] = useState(false);
  const [sliderData, setSliderData] = useState(images[0]);
  const [product, setProduct] = useState(images[0]);
  const [ttl, setTTL] = useState('')
  const [isOutDated, setIsOutDated] = useState(false)
  const [loading, setLoading] = useState(true)
  const [bidValue, setBidValue] = useState('')
  const navigate = useNavigate();

  const user = useSelector(({ auth }) => auth.user.data);

  let timer = undefined
  const { productId } = useParams()
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const showAlert = (connect, isError = true) => {
    addToast(connect, {
      appearance: isError ? "error" : "success",
      autoDismiss: true,
    })
  }

  const setImagesData = (productData) => {
    if (productData?.images?.length > 0) {
      const imagesList = []
      for (let index = 0; index < productData.images.length; index++) {
        const element = productData.images[index];
        let imgUrl = getImageUrl(element)
        imagesList.push(imgUrl)
      }
      setImages(imagesList)
      setSliderData(imagesList[0])
    }
  }

  const updateProductValu = () => {
    const newBidValue = Number(bidValue.trim())
    if (newBidValue - product?.value < 50) {
      showAlert("Minimum bid value is $50")
      return
    }
    setLoading(true)
    dispatch(updateValue({ productId, bidValue: newBidValue, value: product?.value || 0 })).then(({ payload }) => {
      if (payload?.error) {
        showAlert(payload?.error)
        setProduct(payload.product)
      } else {
        setImagesData(payload)
        setProduct(payload)
        clearInterval(timer)
        setTTL('')
        getTTL(payload)
        setBidValue(0)
        showAlert('Your bid submitted successfully', false)
      }
      setLoading(false)
    }).catch(error => {
      setLoading(false)
      showAlert('Something went wrong')
    })
  }

  const getTTL = (productData) => {
    const timeToleft = productData?.endBid
    console.log({ timeToleft })
    const futureDate = new Date(timeToleft).getTime();
    const now = new Date().getTime();
    const timeleft = futureDate - now;
    const days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
    console.log(days + 'D ' + hours + 'H ' + minutes + 'M ' + seconds + ' seconds left')
    if (productData?.isSoldOut || (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0)) {
      setTTL("Auction has ended")
      setIsOutDated(true)
    } else {
      setTTL(days + 'D ' + hours + 'H ' + minutes + 'M ' + seconds + ' seconds left')
      timer = setTimeout(() => {
        getTTL(productData)
      }, 1000);
    }
  }

  function handleClick(index) {
    setSliderData(images[index]);
  }

  const getProductData = () => {
    dispatch(getProduct(productId)).then(({ payload }) => {
      if (payload) {
        setProduct(payload)
        setImagesData(payload)
        if (payload?.endBid) {
          clearInterval(timer)
          getTTL(payload)
        }
        setLoading(false)
      }
    }).catch(error => {
      setLoading(false)
    })
  }

  useEffect(() => {
    getProductData()
  }, []);

  const renderBidInput = () => {
    if (product?.isSoldOut || isOutDated) return <p className="text-red-500 text-base md:text-xl font-bold w-1/2 md:w-1/3">
      SOLD
    </p>


    if (user.isBlocked) return <p className="text-red-500 text-base md:text-xl font-bold w-1/2 md:w-full">
      You are not allowed to buy this product, Please contact the sales department
    </p>

    if (user?.id) {
      return <div className="flex items-center justify-center w-full px-10 space-x-2 md:space-x-8 mt-6">
        <input
          type="number"
          placeholder="Enter amount"
          defaultValue={bidValue}
          onChange={(e) => setBidValue(e.target.value)}
          className="p-2 border border-gray-300 rounded-lg w-1/2 md:w-1/3"
          min={50}
          required
        />
        <button
          className="bg-amber-600 w-1/2 md:w-1/4 text-white p-2 px-6 rounded-lg font-bold"
          onClick={() => {
            updateProductValu()
          }}
        >
          Bid
        </button>
      </div>
    } else {

      return <h1 className="text-base font-bold text-zinc-700 text-center mt-5">
        Want to Bid?{" "}
        <a
          href="#"
          className="text-lg text-amber-600 underline"
          onClick={() => {
            navigate("/SignIn");
          }}
        >
          Login First
        </a>
      </h1>
    }



  }
  if (loading) return <Loader />
  if (!product.id) return <ErrorAnimation />
  return (
    <div className="w-full md:flex items-start justify-center min-h-screen bg-white pt-10 px-4 md:px-8 pb-14">
      <MetaTag url={`https://movingtreasure.com/item/${product.id}`} type="article" title={product?.name} description={product?.description} image={images.length > 0 && images[0]}/>
      <div className="w-full md:w-1/3 h-4/5 md:ml-3">
        <div className="flex md:flex-col items-center justify-center space-y-2">
          <img
            src={sliderData}
            className="w-[16rem] h-[16rem] md:w-[28rem] md:h-[28rem] object-cover rounded-2xl"
            alt="item"
          />
          <div className="max-h-60 grid md:grid-cols-4 gap-2 overflow-scroll md:overflow-visible">
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                className="w-12 h-12 md:w-16 md:h-16 object-cover rounded-lg hover:scale-105 transition hover:cursor-pointer ml-2"
                alt="item"
                onClick={() => handleClick(index)}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="w-full md:w-1/2 h-fit bg-[#e0edf4] mt-3 md:mt-0 md:mx-4 rounded-xl p-5">
        <h1 className="text-zinc-800 text-3xl md:text-4xl font-bold text-center md:text-start font-serif mb-8">
          {product.name}
        </h1>
        <table className="table-auto w-full ">
          <tbody>
            {product?.description && <tr>
              <td className="text-gray-500 text-base md:text-xl font-bold w-1/2 md:w-1/3">
                Description:
              </td>
              <td className="text-base md:text-xl flex items-center font-bold text-zinc-700">
                {product.description}
              </td>
            </tr>}
            {product?.disclaimer && <tr>
              <td className="text-red-500 text-base md:text-xl font-bold w-1/2 md:w-1/3">
                Disclaimer:
              </td>
              <td className="text-base md:text-xl flex items-center font-bold text-red-500 mb-4 italic">
                {product.disclaimer}
              </td>
            </tr>}
            {product?.value && <tr>
              <td className="text-gray-500 text-base md:text-xl font-bold w-1/2 md:w-1/3">
                Current Price:
              </td>
              <td className="text-2xl md:text-3xl flex items-center font-bold text-amber-500">
                ${product.value}
              </td>
            </tr>}
            <tr>
              <td className="text-gray-500 text-base md:text-xl font-bold w-1/2 md:w-1/3">
                Time Left:
              </td>
              <td className="text-base md:text-xl flex items-center font-bold text-zinc-700">
                {ttl}
              </td>
            </tr>
            {product?.lastUser && <tr>
              <td className="text-base md:text-xl font-bold w-1/2 md:w-1/3 text-gray-500">
                Highest Bidder:
              </td>
              <td className="text-base md:text-xl flex items-center font-bold text-zinc-700">
                {product.lastUser}
              </td>
            </tr>}
            <tr>
              <td className="text-base md:text-xl font-bold w-1/2 md:w-1/3 text-gray-500">
                Minimum Bid:
              </td>
              <td className="text-base md:text-xl flex items-center font-bold text-zinc-700">
                $50
              </td>
            </tr>
            {product.numberOfBids && <tr>
              <td className="text-base md:text-xl font-bold w-1/2 md:w-1/3 text-gray-500">
                Number of Offers:
              </td>
              <td className="text-base md:text-xl flex items-center font-bold text-zinc-700">
                {product.numberOfBids}
              </td>
            </tr>}
            {product?.startBid && <tr>
              <td className="text-base md:text-xl font-bold w-1/2 md:w-1/3 text-gray-500">
                Auction Start Date:
              </td>
              <td className="text-base md:text-xl flex items-center font-bold text-zinc-700">
                {moment(product.startBid).format('MMMM/DD/yyyy')}
              </td>
            </tr>}
            {product?.endBid && <tr>
              <td className="text-base md:text-xl font-bold w-1/2 md:w-1/3 text-gray-500">
                Auction End Date:
              </td>
              <td className="text-base md:text-xl flex items-center font-bold text-zinc-700">
                {moment(product.endBid).format('MMMM/DD/yyyy')}
              </td>
            </tr>}
            {product?.shipmentLocation && <tr>
              <td className="text-base md:text-xl font-bold w-1/2 md:w-1/3 text-gray-500">
                Pick-up Location:
              </td>
              <td className="text-base md:text-xl flex items-center font-bold text-zinc-700">
                {product.shipmentLocation}
              </td>
            </tr>}
            <tr>
              <td className="text-base md:text-xl font-bold w-1/2 md:w-1/3 text-gray-500">
                Payment Options:
              </td>
              <td className="text-base md:text-xl flex items-center font-bold text-zinc-700">
                We would like to present the available payment options for your convenience:
                <br />
                1. Cash: Payments can be made in cash either prior to the scheduled pickup or at the time of pickup.
                <br />
                2. Postal Money Order: We also accept payments in the form of post office money orders. Please ensure that the money order is prepared and presented prior to or during the pickup.
                <br />
                3. Zelle: For an electronic payment solution, we accept transactions made through Zelle. Kindly arrange the payment prior to the pickup or at the time of pickup.
                <br />
              </td>
            </tr>
            <tr>
              <td className="text-base md:text-xl font-bold w-1/2 md:w-1/3 text-gray-500">
                Shipping:
              </td>
              <td className="text-base md:text-xl flex items-center font-bold text-zinc-700">
                Please note that it is a requirement to collect the auctioned items after winning. Should you prefer a hassle-free option, we are pleased to offer delivery services for an additional fee. Our delivery coverage extends to all lower 48 states. Please do not hesitate to contact us for accurate shipping quotes tailored to your specific location.
              </td>
            </tr>
          </tbody>
        </table>
        {renderBidInput()}

      </div>
    </div>
  );
};

export default Item;
