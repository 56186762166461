import Lottie from 'react-lottie';

const Loader = () => {
    return (<>
        <Lottie
            options={{
                path: 'https://lottie.host/6b150838-312f-4017-98bc-30446fbe85db/v7BgNNgWke.json',
            }}
            width={200}
            height={200}
        />
    </>)
}

export default Loader
