import React from "react";
import abtImg from "../../assets/abttt.png";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaQ, FaQuoteLeft, FaQuoteRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import MetaTag from "../../components/metaTag";

import {
  FaGavel,
  FaShieldAlt,
  FaShippingFast,
  FaUser,
  FaMoneyBillWave,
} from "react-icons/fa";
import {
  PiNumberSquareOneFill,
  PiNumberTwoFill,
  PiNumberThreeFill,
  PiNumberFourFill,
  PiNumberFiveFill,
  PiNumberSixFill,
} from "react-icons/pi";

function AboutUs() {
  const navigate = useNavigate();
  const user = useSelector(({ auth }) => auth.user.data);

  return (
    <div className="w-full pt-10  rounded-3xl bg-white px-4 md:px-8">
      <MetaTag />
      <h1 className="text-2xl md:text-4xl font-semibold font-serif mb-4 ml-4 text-start">
        About Us
      </h1>
      <div className="bg-[#e0edf4] rounded-3xl">
        <img
          src={abtImg}
          alt="About Us"
          className="w-full  md:h-96 object-cover object-center rounded-2xl"
        />
        <div className="w-full flex-col items-center justify-center py-6 px-6 ">
          <h1 className="text-3xl md:text-5xl text-zinc-900 font-semibold font-serif mb-4 text-center">
            Moving Treasure
          </h1>
          <p className="text-lg text-zinc-600 italic text-center md:w-1/2 mx-auto">
            Our goal is to connect individuals with abandoned shipments and help
            them find new homes, while also providing a platform for sellers to
            recover value from their unwanted items
          </p>
          <div className="flex-col items-center justify-center text-center mt-4">
            {!user?.id && <a
              className="text-lg text-white font-bold bg-amber-500 p-2 px-2 md:px-4 rounded-xl hover:cursor-pointer hover:opacity-85 transition"
              onClick={() => {
                navigate("/SignUp");
              }}
            >
              Create Account
            </a>}
            <h1 className="text-xl text-zinc-900 font-semibold font-serif mb-2 mt-4">
              Find us on
            </h1>
            <div className="flex items-center justify-center space-x-4">
              <a
                href="https://www.facebook.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookSquare className="text-4xl text-zinc-900" />
              </a>
              <a
                href="https://www.instagram.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagramSquare className="text-4xl text-zinc-900" />
              </a>
            </div>
          </div>
          <div className="flex-col items-center justify-center text-center mt-6 space-x-4">
            <a
              className="text-lg text-white font-medium font-serif mb-2 bg-zinc-900 p-2 px-2 md:px-4 rounded-xl hover:cursor-pointer hover:opacity-85 transition"
              href="PrivacyPolicy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            <a
              className="text-lg text-white font-medium font-serif mb-2 bg-zinc-900 p-2 px-4 rounded-xl hover:cursor-pointer hover:opacity-85 transition"
              href="TermsOfUse"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Use
            </a>
          </div>
        </div>
      </div>

      <p className="md:w-1/2 mx-auto text-base md:text-lg font-semibold text-zinc-600 font-sans text-center mt-5 italic">
        <FaQuoteLeft className="inline-block text-xl text-zinc-700 ml-3 mr-1 mb-2" />
        Welcome to MovingTreasure, our Moving Auction Warehouse, your one-stop
        destination for finding incredible deals on abandoned shipments. We are
        dedicated to offering a unique and exciting online auction experience
        where you can discover hidden treasure at unbeatable prices.
        <FaQuoteRight className="inline-block text-xl text-zinc-700 ml-1 mb-2" />
      </p>
      <h1 className="text-5xl text-zinc-900 font-bold font-sans text-center mt-4 border-b border-gray-300 pb-3">
        Our Services
      </h1>
      <p className="mt-4 text-center text-base md:text-lg font-serif text-zinc-500 ">
        Moving Treasure offers a range of services designed to provide a
        seamless and convenient experience for both buyers and sellers. Our goal
        is to connect individuals with abandoned shipments and help them find
        new homes, while also providing a platform for sellers to recover value
        from their unwanted items. Here are the key services we provide:
      </p>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-8 bg-[#e0edf4] rounded-3xl px-2 items-start">
        <div className="w-full flex flex-col items-center justify-center p-4 text-center">
          <FaGavel className="text-4xl mb-2" />
          <h2 className="text-2xl font-bold">Online Auction Platform</h2>
          <p>
            Our website serves as a user-friendly online auction platform, where
            buyers can browse, bid, and win abandoned shipments from previous
            clients. Through our secure bidding system, participants can engage
            in real-time auctions and enjoy the thrill of competition from the
            comfort of their own homes.
          </p>
        </div>

        <div className="w-full flex flex-col items-center justify-center p-4 text-center">
          <FaShieldAlt className="text-4xl mb-2" />
          <h2 className="text-2xl font-bold">Secure Bidding Process</h2>
          <p>
            We prioritize the security and integrity of our bidding process. Our
            platform employs robust measures to safeguard user information,
            prevent fraudulent activity, and ensure fair competition among
            bidders. We aim to create a trusted and reliable environment for all
            participants.
          </p>
        </div>

        <div className="w-full flex flex-col items-center justify-center p-4 text-center">
          <FaShippingFast className="text-4xl mb-2" />
          <h2 className="text-2xl font-bold">Convenient Shipping Solutions</h2>
          <p>
            Once an auction ends, the shipment must be picked up from our local
            warehouse, or we can ship it to any of the lower 48 states with
            extra fees. We will work with your schedule to ensure a hassle-free
            buying experience.
          </p>
        </div>

        <div className="md:flex md:col-span-3 md:w-3/4 mx-auto items-start">
          <div className="md:w-1/2 flex flex-col items-center justify-center p-4 text-center">
            <FaUser className="text-4xl mb-2" />
            <h2 className="text-2xl font-bold">Buyer Support</h2>
            <p>
              Our customer support team is available to assist buyers throughout
              the auction process. Whether you have questions about specific
              items, bidding procedures, or shipping details, our knowledgeable
              team is here to provide prompt and friendly assistance.
            </p>
          </div>

          <div className="md:w-1/2 flex flex-col items-center justify-center p-4 text-center">
            <FaMoneyBillWave className="text-4xl mb-2" />
            <h2 className="text-2xl font-bold">Seller Opportunities</h2>
            <p>
              If you have abandoned shipments or unwanted items, our platform
              offers a convenient way to recover value from them. By partnering
              with us, you can list your items for auction and reach a wide
              audience of potential buyers. We handle the entire process, from
              cataloging to shipping, allowing you to focus on maximizing
              returns.
            </p>
          </div>
        </div>
      </div>

      <div className="max-w-screen-xl mx-auto py-16 ">
        <h1 className="text-3xl md:text-4xl font-bold mb-8 text-center font-serif">
          Welcome to Moving Treasure
        </h1>

        {/* Point 1 */}
        <div className="flex items-center mb-8 italic">
          <div className="flex-shrink-0 mr-4">
            <PiNumberSquareOneFill className="text-4xl text-amber-500" />
          </div>
          <div>
            <p className="text-lg">
              We strive to provide an exceptional auction experience for both
              buyers and sellers. Our services are designed to simplify the
              process, encourage fair competition, and ensure customer
              satisfaction.
            </p>
          </div>
        </div>

        {/* Point 2 */}
        <div className="flex items-center mb-8 italic">
          <div className="flex-shrink-0 mr-4">
            <PiNumberTwoFill className="text-4xl text-amber-500" />
          </div>
          <div>
            <p className="text-lg">
              We understand that sometimes items get left behind during the
              moving process. Whether it's due to a change of plans, downsizing,
              or simply forgetting about certain belongings, we believe that
              these abandoned shipments deserve a chance to find new homes.
            </p>
          </div>
        </div>

        {/* Point 3 */}
        <div className="flex items-center mb-8 italic">
          <div className="flex-shrink-0 mr-4">
            <PiNumberThreeFill className="text-4xl text-amber-500" />
          </div>
          <div>
            <p className="text-lg">
              Our auction platform provides a safe and convenient way for buyers
              to browse and bid on a wide variety of items. From furniture and
              appliances to electronics and collectibles filled with a diverse
              range of treasure waiting to be discovered.
            </p>
          </div>
        </div>

        {/* Point 4 */}
        <div className="flex items-center mb-8 italic">
          <div className="flex-shrink-0 mr-4">
            <PiNumberFourFill className="text-4xl text-amber-500" />
          </div>
          <div>
            <p className="text-lg">
              Our user-friendly website makes it easy for you to browse and
              search for items that catch your interest. With our secure bidding
              system, you can participate in auctions from the comfort of your
              own home, placing bids and monitoring your favorite items in
              real-time.
            </p>
          </div>
        </div>

        {/* Point 5 */}
        <div className="flex items-center mb-8 italic">
          <div className="flex-shrink-0 mr-4">
            <PiNumberFiveFill className="text-4xl text-amber-500" />
          </div>
          <div>
            <p className="text-lg">
              Once the auction ends, our dedicated team ensures a seamless and
              hassle-free pick-up process. Or simply, you can use our shipping
              option to get the items delivered to you anywhere in the lower 48
              states.
            </p>
          </div>
        </div>

        {/* Point 6 */}
        <div className="flex items-center mb-8 italic">
          <div className="flex-shrink-0 mr-4">
            <PiNumberSixFill className="text-4xl text-amber-500" />
          </div>
          <div>
            <p className="text-lg">
              Join us today and experience the thrill of discovering incredible
              deals on abandoned shipments. Be a part of our growing community
              and let us help you find the perfect items to enhance your home or
              collection.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
