import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import axios from 'axios';

export const getProduct = createAsyncThunk(
  'productApp/product/getProduct',
  async (productId, { dispatch }) => {
    const response = await axios.get(`product/${productId}`);
    return response.data;
  }
);

export const updateValue = createAsyncThunk(
  'productApp/product/updateValue',
  async ({ productId, value, bidValue }) => {
    const response = await axios.post(`product/${productId}`, { bidValue, value });
    return response.data;
  }
);

const productsSlice = createSlice({
  name: 'productApp/product',
  initialState: {
    buyerPremiumPercentage: '',
    createdAt: '',
    description: '',
    disclaimer: '',
    endBid: '',
    id: '',
    images: [],
    isApproved: false,
    isPublish: true,
    isSoldOut: false,
    lastUser: "",
    lastUserId: "",
    name: "",
    numberOfBids: 0,
    numberOfPieces: 0,
    shipmentLocation: "",
    shipmentNumber: "",
    startBid: "",
    taxPercentage: 0,
    updatedAt: "",
    value: 0
  },
  reducers: {
    setTotalResults: {
      reducer: (state, action) => {
        state.totalResults = action.payload ? action.payload : 0;
      },
      prepare: totalResults => ({ payload: totalResults || '' }),
    },
  },
});

export const {
  setTotalResults,
} = productsSlice.actions;

export default productsSlice.reducer;

