import React, { useState, useEffect } from "react";
import imgBg from "../../assets/aboutus.jpg";
import { useNavigate } from "react-router-dom";
import { useToasts } from 'react-toast-notifications'
import { submitRegister } from "../../auth/store/registerSlice"
import { useDispatch, useSelector } from 'react-redux';
import MetaTag from "../../components/metaTag";

const SignUp = () => {
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState();
  const [address, setAddress] = useState();
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const { addToast } = useToasts()
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user.data);

  const showAlert = (connect) => {
    addToast(connect, {
      appearance: "error",
      autoDismiss: true,
    })
  }

  useEffect(() => {
    if (user.id) {
      navigate("/");
    }
  }, [dispatch, user])

  const submitHandler = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email === '' ||
      password === '' ||
      address === '' ||
      firstName === '' ||
      lastName === '' ||
      phoneNumber === '') {
      return showAlert("All fields are required")
    } else if (!emailRegex.test(email)) {
      return showAlert("Please enter a valid email address")
    } else if (password.length < 8 || password.length > 20) {
      return showAlert("Password should be between 8 and 20 characters long")
    } else if (!isChecked) {
      return showAlert("Please accept the terms and conditions")
    }
    const userData = {
      email,
      password,
      address,
      firstName,
      lastName,
      phoneNumber,
    }

    console.log({userData})
    dispatch(submitRegister(userData))
  }

  return (
    <div className="flex justify-center items-center min-h-screen bg-[#e0edf4] mx-4 my-4 md:mx-8 rounded-3xl">
      <MetaTag />
      <div className="w-full flex flex-col items-center justify-center md:w-1/2 px-8 py-4">
        <h1 className="text-3xl font-bold mb-8 text-center font-serif text-zinc-700">
          Create an Account
        </h1>
        <div className="flex flex-col md:flex-row md:space-x-2 w-full md:w-5/6">
          <div className="flex-1">
            <p className="text-base font-bold text-zinc-600 mb-1 ml-2">
              First Name
            </p>
            <input
              onChange={(e) => setFirstName(e.target.value)}
              type="text"
              placeholder="Enter your first name"
              className="w-full px-4 py-2 mb-2 border rounded-lg focus:outline-none focus:border-amber-500 bg-neutral-50"
            />
          </div>
          <div className="flex-1">
            <p className="text-base font-bold text-zinc-600 mb-1 ml-2">
              Last Name
            </p>
            <input
              onChange={(e) => setLastName(e.target.value)}
              type="text"
              placeholder="Enter your last name"
              className="w-full px-4 py-2 mb-2 border rounded-lg focus:outline-none focus:border-amber-500 bg-neutral-50"
            />
          </div>
        </div>
        <div className="flex flex-col justify-center w-full md:w-5/6">
          <p className="text-base font-bold text-zinc-600 mb-1 ml-2">
            Mobile Number
          </p>
          <input
            onChange={(e) => setPhoneNumber(e.target.value)}
            type="tel"
            placeholder="Enter your mobile number"
            className="w-full px-4 py-2 mb-2 border rounded-lg focus:outline-none focus:border-amber-500 bg-neutral-50"
          />
        </div>

        <div className="flex flex-col justify-center w-full md:w-5/6">
          <p className="text-base font-bold text-zinc-600 mb-1 ml-2">Email</p>
          <input
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="Enter your email"
            className="w-full px-4 py-2 mb-2 border rounded-lg focus:outline-none focus:border-amber-500 bg-neutral-50"
          />
        </div>
        <div className="flex flex-col justify-center w-full md:w-5/6">
          <p className="text-base font-bold text-zinc-600 mb-1 ml-2">
            Password
          </p>
          <input
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            placeholder="Enter password"
            className="w-full px-4 py-2 mb-2 border rounded-lg focus:outline-none focus:border-amber-500 bg-neutral-50"
          />
        </div>

        <div className="flex flex-col justify-center w-full md:w-5/6">
          <p className="text-base font-bold text-zinc-600 mb-1 ml-2">Address</p>
          <input
            onChange={(e) => setAddress(e.target.value)}
            type="text"
            placeholder="Enter your address"
            className="w-full px-4 py-2 mb-2 border rounded-lg focus:outline-none focus:border-amber-500 bg-neutral-50"
          />
        </div>
        <div className="flex justify-between text-sm mb-2 w-full md:w-5/6">
          <a
            href="/PrivacyPolicy"
            target="_blank"
            className="text-amber-500 font-bold hover:cursor-pointer"
          >
            Privacy Policy
          </a>
          <a
            href="/TermsOfUse"
            target="_blank"
            className="text-amber-500 font-bold hover:cursor-pointer"
          >
            Terms of Use
          </a>
        </div>

        <div className="flex items-center w-full md:w-5/6 mb-4">
          <input onChange={e => setIsChecked(!isChecked)} type="checkbox" className="mr-2 hover:cursor-pointer" />
          <p className="text-sm text-zinc-600 font-bold">
            I accept the terms and conditions
          </p>
        </div>

        {/* Sign Up Button */}
        <button onClick={() => submitHandler()} className="bg-amber-500 px-5 py-2 rounded-lg font-bold text-neutral-100 flex items-center justify-center hover:shadow-md hover:shadow-amber-500 w-1/2 mx-auto mt-2 mb-4">
          Sign Up
        </button>

        <p className="text-center">
          Already have an account?
          <a
            onClick={() => {
              navigate("/SignIn");
            }}
          >
            <span className="text-amber-500 font-bold text-base normal-case hover:cursor-pointer">
              {" "}
              Sign In
            </span>
          </a>
        </p>
      </div>
      <div
        style={{ backgroundImage: `url(${imgBg})` }}
        className="object-cover hidden md:w-1/2 h-[100vh] bg-center bg-cover md:flex flex-col items-center justify-center rounded-2xl"
      >
        <h1 className="w-full text-3xl md:text-5xl font-bold text-neutral-50 text-center mb-4">
          Moving Treasure
        </h1>
        <p className="text-base md:text-2xl w-9/12 md:w-3/4 font-semibold text-neutral-100 text-center">
          Your Treasure, One Click Away.
        </p>
      </div>
    </div>
  );
};

export default SignUp;
