import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import axios from 'axios';

export const getProducts = createAsyncThunk(
  'productsApp/products/getProducts',
  async ({ page, limit, userId }, { dispatch }) => {
    const response = await axios.get('product', {
      params: {
        page: page,
        limit,
        userId,
      },
    });

    dispatch(setTotalResults(response.data.total));
    return response.data.results;
  }
);

export const getMyProducts = createAsyncThunk(
  'productsApp/products/getMyProducts',
  async ({ page, limit }) => {
    const response = await axios.get('product/myProduct', {
      params: {
        page: page + 1,
        limit,
      },
    });
    return response.data;
  }
);

const productsSlice = createSlice({
  name: 'productsApp/products',
  initialState: {
    totalResults: 0,
    data: [],
    myProducts: []
  },
  reducers: {
    setTotalResults: {
      reducer: (state, action) => {
        state.totalResults = action.payload ? action.payload : 0;
      },
      prepare: totalResults => ({ payload: totalResults || '' }),
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getProducts.fulfilled, (state, action) => {
        state.data = action.payload
      })
      .addCase(getMyProducts.fulfilled, (state, action) => {
        state.myProducts = action.payload
      })
  },
});

export const {
  setTotalResults,
} = productsSlice.actions;

export default productsSlice.reducer;

