import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
import Footer from "./components/footer";
import Home from "./pages/Home/Index";
import Item from "./pages/Item/Index";
import AboutUs from "./pages/AboutUs";
import SignIn from "./pages/Authentication/SignIn";
import SignUp from "./pages/Authentication/SignUp";
import ContactUs from "./pages/ContactUs";
import Blogs from "./pages/Blogs/Index";
import BlogItem from "./pages/Blogs/BlogItem";
import Profile from "./pages/Profile";
import BidsWon from "./pages/BidsWon";
import TermsOfUse from "./pages/TermsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";
// import store from './store';
import { Auth } from './auth';
// import { Provider } from "react-redux";
import { ToastProvider } from 'react-toast-notifications';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  const helmetContext = {};

  return (
    <>
      <ToastProvider>
        <HelmetProvider context={helmetContext}>
          <BrowserRouter>
            <Auth >
              <Navbar />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/item/:productId" exact element={<Item />} />
                <Route path="/BidsWon" exact element={<BidsWon />} />
                <Route path="/AboutUs" exact element={<AboutUs />} />
                <Route path="/SignIn" exact element={<SignIn />} />
                <Route path="/SignUp" exact element={<SignUp />} />
                <Route path="/ContactUs" exact element={<ContactUs />} />
                <Route path="/Blogs" exact element={<Blogs />} />
                <Route path="/Blogs/:blogId" exact element={<BlogItem />} />
                <Route path="/Profile" exact element={<Profile />} />
                <Route path="/TermsOfUse" exact element={<TermsOfUse />} />
                <Route path="/PrivacyPolicy" exact element={<PrivacyPolicy />} />
                <Route key="def" path="*" element={<Navigate to="/" />} />
              </Routes>
            </Auth>
            <Footer />
          </BrowserRouter >
        </HelmetProvider>
      </ToastProvider>
    </>
  );
}

export default App;
