import React, { useEffect, useState } from "react";
import imgBg from "../../assets/aboutus.jpg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications'
import { submitLogin } from "../../auth/store/loginSlice"
import { validateEmail } from '../../helper'
import MetaTag from "../../components/metaTag";

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const user = useSelector(({ auth }) => auth.user.data);
  const { addToast } = useToasts()

  useEffect(() => {
    if (user.id) {
      navigate("/");
    }
  }, [dispatch, user])

  const showAlert = (connect, isError = true) => {
    addToast(connect, {
      appearance: isError ? "error" : "success",
      autoDismiss: true,
    })
  }

  const handleLogin = () => {

    if (email.length === 0 && password.length === 0) {
      showAlert('Please enter email and password');
      return;
    }

    if (!validateEmail(email)) {
      showAlert('Please enter a valid email address');
      return;
    }
    if (password.length < 6) {
      showAlert("Password must be at least 6 characters long");
      return;
    }

    dispatch(submitLogin({ email, password })).then((res) => {
      if (res.type === 'auth/login/loginError') {
        showAlert(res.payload)
      }
    });
  }
  return (
    <div className="min-h-screen mx-4 my-4 md:mx-8">
      <MetaTag />
      <div className="flex justify-center items-center h-full bg-[#e0edf4] rounded-3xl">
        <div
          style={{ backgroundImage: `url(${imgBg})` }}
          className="object-cover hidden md:w-1/2 h-[85vh] bg-center bg-cover md:flex flex-col items-center justify-center rounded-2xl"
        >
          <h1 className="w-full text-3xl md:text-5xl font-bold text-neutral-50 text-center mb-4">
            Moving Treasure
          </h1>
          <p className="text-base md:text-2xl w-9/12 md:w-3/4 font-semibold text-neutral-100 text-center ">
            Find It. Bid It. Win It
          </p>
        </div>
        <div className="w-full flex flex-col justify-center md:w-1/2 p-8 ">
          <h1 className="text-3xl font-bold mb-8 text-center font-serif text-zinc-700">
            Welcome Back
          </h1>

          <div className="flex flex-col  justify-center w-full md:w-3/4 mx-auto text-start">
            <p className="text-base font-bold text-zinc-600 mb-1 ml-2 ">
              Email
            </p>
            <input
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="Enter your email"
              className="w-full px-4 py-2 mb-4 border rounded-lg focus:outline-none focus:border-amber-500 bg-neutral-50"
            />
          </div>

          <div className="flex flex-col  justify-center w-full md:w-3/4 mx-auto text-start">
            <p className="text-base font-bold text-zinc-600 mb-1 ml-2">
              Password
            </p>
            <input
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder="Enter password"
              className="w-full px-4 py-2 mb-4 border rounded-lg focus:outline-none focus:border-amber-500 bg-neutral-50"
            />
          </div>
          <button onClick={handleLogin} className="bg-amber-500 px-5 py-2 rounded-lg font-bold text-neutral-100 flex items-center justify-center hover:shadow-md hover:shadow-amber-500 w-1/2 mx-auto mt-4 mb-4">
            Login
          </button>

          <p className="text-center">
            Don't have an account?
            <a
              onClick={() => {
                navigate("/SignUp");
              }}
            >
              <span className="text-amber-500 font-bold text-base normal-case hover:cursor-pointer">
                {" "}
                Sign Up
              </span>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
